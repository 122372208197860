import { Node } from "tiptap";

export default class Iframe extends Node {
  get name() {
    return "pdf";
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null
        }
      },
      group: "block",
      selectable: false,

      parseDOM: [
        {
          tag: "iframe",
          getAttrs: dom => ({
            src: dom.getAttribute("src")
          })
        }
      ],

      toDOM: node => [
        "div",
        {
          class: "iframe-container shadow"
        },
        [
          "iframe",
          {
            src: `https://docs.google.com/viewer?url=${encodeURIComponent(
              node.attrs.src
            )}&embedded=true`
            // https://docs.google.com/viewer?url='+encodedUrl+'&embedded=true
            // You can set the width and height here also
          }
        ],
        [
          "a",
          {
            href: node.attrs.src,
            download: true,
            class: "download-pdf"
          },
          "Download"
        ]
      ]
    };
  }

  commands({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }
}
