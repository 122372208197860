<template>
  <div>
    <div
      v-if="show"
      class="fixed w-screen h-screen flex items-center justify-center z-80 top-0 left-0"
    >
      <div
        @click="show = false"
        class="bg-blue-900 opacity-50 absolute top-0 left-0 w-full h-screen"
      ></div>
      <div
        class="modal bg-white rounded shadow-lg relative z-10 flex flex-col justify-between"
      >
        <header
          class="px-4 py-3 border-b border-gray-300 font-bold text-gray-600"
        >
          {{ modalTitle }}
        </header>

        <div class="content flex p-4">
          <div class="w-full pl-3">
            <label
              for="selectPDF"
              class="bg-gray-100 hover:bg-gray-200 rounded flex justify-center items-center cursor-pointer w-full h-64"
            >
              <iframe
                v-if="localPDF"
                :src="`/pdfjs/web/viewer.html?file=${localPDF}`"
                type="application/pdf"
                class="object-contain h-64 w-full"
              />

              <div v-else>
                Selecione um PDF
              </div>

              <input
                type="file"
                class="absolute top-0 left-0 w-0 h-0"
                id="selectPDF"
                name="selectPDF"
                accept="application/pdf"
                @change="detectFiles($event.target.files)"
              />
            </label>
          </div>
        </div>

        <footer class="px-4 py-3 border-t border-gray-300 text-right">
          <AppButton type="button" @clicked="handleData()" :loading="loading"
            >Adicionar PDF</AppButton
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapState, mapGetters } from 'vuex';
import AppButton from "@/components/AppButton";

export default {
  name: "ModalPDF",
  components: {
    AppButton
  },
  filters: {},
  props: {
    modalTitle: String
  },
  data: function() {
    return {
      loading: false,
      file: null,

      image: null,

      command: null,
      show: false,

      src: ""
    };
  },
  computed: {
    localPDF() {
      if (this.file) {
        return URL.createObjectURL(this.file);
      } else {
        return false;
      }
    }
  },
  watch: {
    show() {
      this.file = null;
    }
  },
  async created() {},
  async mounted() {},
  methods: {
    showModal(command) {
      console.log(command);
      this.command = command;
      this.show = true;
    },

    youtubeParser(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },

    async handleData() {
      this.loading = true;

      let src = "";

      try {
        const uploadData = {
          folder: "images",
          file: this.file
        };
        src = await this.$store.dispatch("Images/uploadImage", uploadData);

        console.log(src);
        this.loading = false;

        const data = {
          command: this.command,
          data: {
            src: src
          }
        };

        this.loading = false;

        this.$emit("onConfirm", data);
        this.show = false;
      } catch (err) {
        console.log(err);
      }
    },

    async detectFiles(fileList) {
      this.file = fileList[0];
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  width: 480px;
  height: 430px;
}
</style>
