/* eslint-disable */
import { Mark } from "tiptap";
import { updateMark, removeMark } from 'tiptap-commands'

export default class GlossaryEntry extends Mark {
  get name() {
    return "glossaryEntry";
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null,
        },
      },
      inclusive: false,
      toDOM: node => ["span", {
        class: "glossaryEntry",
        "data-glossary": node.attrs.src
      }, 0]
    }
  }

  commands({ type }) {
    return attrs => {
      if (attrs.src) {
        return updateMark(type, attrs)
      }

      return removeMark(type)
    }
  }
}
