<template>
  <div class="editor container mb-5">
    <ModalIframe
      ref="modalIframe"
      @onConfirm="addCommand"
      modalTitle="Adicionar iframe"
    />

    <ModalPDF
      ref="modalPDF"
      @onConfirm="addCommand"
      modalTitle="Adicionar PDF"
    />

    <ModalImage
      ref="modalImage"
      @onConfirm="addCommand"
      modalTitle="Adicionar imagem"
    />

    <ModalGlossary
      ref="modalGlossary"
      @onConfirm="addCommand"
      modalTitle="Linkar verbete"
    />

    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
          title="Negrito"
        >
          <icon name="bold" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
          title="Italico"
        >
          <icon name="italic" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
          title="Riscado"
        >
          <icon name="strike" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
          title="Sublinhado"
        >
          <icon name="underline" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
          title="Parágrafo normal"
        >
          <icon name="paragraph" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
          title="Título primário"
        >
          H1
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
          title="Título secundário"
        >
          H2
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
          title="Título terciário"
        >
          H3
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
          title="Lista de pontos"
        >
          <icon name="ul" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
          title="Lista numérica"
        >
          <icon name="ol" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
          title="Citação"
        >
          <icon name="quote" />
        </button>

        <button class="menubar__button" @click="showLinkPrompt(commands.link)">
          <icon name="link" />
        </button>

        <button
          class="menubar__button"
          @click="openModal('modalImage', commands.image)"
        >
          <icon name="image" />
        </button>

        <button
          class="menubar__button"
          @click="openModal('modalIframe', commands.iframe)"
          title="Adicionar embed do youtube"
        >
          <icon name="iframe" />
        </button>

        <button
          class="menubar__button"
          @click="openModal('modalPDF', commands.pdf)"
          title="Adicionar PDF"
        >
          PDF
        </button>

        <button class="menubar__button" @click="commands.horizontal_rule">
          <icon name="hr" />
        </button>

        <button
          class="menubar__button"
          @click="commands.undo"
          title="Desfazer edição"
        >
          <icon name="undo" />
        </button>

        <button
          class="menubar__button"
          @click="commands.redo"
          title="Refazer edição"
        >
          <icon name="redo" />
        </button>

        <button
          class="menubar__button"
          @click="openModal('modalGlossary', commands.glossaryEntry)"
          title="Linkar verbete"
        >
          ABC
        </button>

        <button
          class="menubar__button"
          @click="
            commands.createTable({
              rowsCount: 3,
              colsCount: 3,
              withHeaderRow: false
            })
          "
        >
          <icon name="table" />
        </button>

        <span v-if="isActive.table()">
          <button class="menubar__button" @click="commands.deleteTable">
            <icon name="delete_table" />
          </button>
          <button class="menubar__button" @click="commands.addColumnBefore">
            <icon name="add_col_before" />
          </button>
          <button class="menubar__button" @click="commands.addColumnAfter">
            <icon name="add_col_after" />
          </button>
          <button class="menubar__button" @click="commands.deleteColumn">
            <icon name="delete_col" />
          </button>
          <button class="menubar__button" @click="commands.addRowBefore">
            <icon name="add_row_before" />
          </button>
          <button class="menubar__button" @click="commands.addRowAfter">
            <icon name="add_row_after" />
          </button>
          <button class="menubar__button" @click="commands.deleteRow">
            <icon name="delete_row" />
          </button>
          <button class="menubar__button" @click="commands.toggleCellMerge">
            <icon name="combine_cells" />
          </button>
        </span>
      </div>
    </editor-menu-bar>
    <editor-content class="editor__content" :editor="editor"></editor-content>
  </div>
</template>

<script>
import Doc from "./Doc";
import Title from "./Title";
import Icon from "@/components/TextEditor/Icon";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";

import Iframe from "./Iframe";
import PDF from "./PDF";
import GlossaryEntry from "./GlossaryEntry";

import ModalIframe from "./ModalIframe";
import ModalPDF from "./ModalPDF";
import ModalImage from "./ModalImage";
import ModalGlossary from "./ModalGlossary";

import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  HorizontalRule,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  Placeholder,
  Image
} from "tiptap-extensions";
export default {
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
    ModalIframe,
    ModalPDF,
    ModalImage,
    ModalGlossary
  },
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      keepInBounds: true,

      showImageModal: false,
      command: null,

      content: "",

      editor: new Editor({
        autoFocus: true,
        onUpdate: ({ getHTML }) => {
          const newContent = getHTML();
          this.setContent(newContent);
        },
        content: this.value,
        extensions: [
          new Doc(),
          new Title(),
          new Placeholder({
            showOnlyCurrent: false,
            emptyNodeText: node => {
              if (node.type.name === "title") {
                return "Título";
              }
              return "Conteúdo";
            }
          }),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Iframe(),
          new PDF(),
          new Image(),
          new GlossaryEntry(),
          new Table({
            resizable: true
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow()
        ]
      })
    };
  },
  methods: {
    setContent(content) {
      this.$emit("input", content);
    },

    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        command({ src });
      }
    },

    openModal(modal, command) {
      this.$refs[modal].showModal(command);
    },

    addCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },

    showLinkPrompt(command) {
      const url = prompt("Adicione a URL que deseja adicionar à palavra:");
      if (url !== null) {
        command({ href: url });
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss">
.editor *.is-empty:nth-child(1)::before,
.editor *.is-empty:nth-child(2)::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
}

.editor {
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-indent: 4rem;
  }

  a {
    text-decoration: underline;
    color: #3182ce;
  }

  hr {
    margin: 2rem 0;
    border-color: #ccc;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  blockquote p,
  ul li p,
  ol li p {
    text-indent: 0;
  }

  blockquote {
    padding: 1rem 0 1rem 4rem;
    font-size: 1.3rem;

    p {
      font-size: 1.3rem;
    }
  }

  ul {
    list-style: circle;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  ol {
    list-style: decimal;
    text-indent: 0;
    margin: 2rem 0 2rem 3.8rem;
    padding: 0;

    li {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 1.4rem;
    }
  }

  table {
    width: calc(90% - 4rem);
    margin: 2rem 0 2rem 4rem;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid #ccc;
    padding: 6px;

    p {
      font-size: 1rem;
      text-indent: 0;
    }
  }

  th,
  th p {
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
}

.menubar {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 50;
  padding: 12px 0;
  left: 0;
}

.menubar__button {
  color: #333;
  background: #fff;
  padding: 6px;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  margin: 0 4px 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.glossaryEntry {
  background: #95ddea;
  padding: 0 4px;
  border-radius: 3px;

  &:hover {
    background: #66c7d9;
  }
}
</style>
