<template>
  <div>
    <div
      v-if="show"
      class="fixed w-screen h-screen flex items-center justify-center z-80 top-0 left-0"
    >
      <div
        @click="show = false"
        class="bg-blue-900 opacity-50 absolute top-0 left-0 w-full h-screen"
      ></div>
      <div
        class="modal bg-white rounded shadow-lg relative z-10 flex flex-col justify-between"
      >
        <header
          class="px-4 py-3 border-b border-gray-300 font-bold text-gray-600"
        >
          {{ modalTitle }}
        </header>

        <div class="content p-4">
          <label class="block mb-6">
            <span class="label">URL do Youtube</span>
            <input type="text" class="input" v-model="src" />
          </label>
        </div>

        <footer class="px-4 py-3 border-t border-gray-300 text-right">
          <AppButton type="button" @clicked="handleData()" :loading="loading"
            >Adicionar iframe</AppButton
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapState, mapGetters } from 'vuex';
import AppButton from "@/components/AppButton";

export default {
  name: "ModalIframe",
  components: {
    AppButton
  },
  filters: {},
  props: {
    modalTitle: String
  },
  data: function() {
    return {
      loading: false,

      command: null,
      show: false,

      src: ""
    };
  },
  computed: {
    // ...mapState({
    //   item: state => state.namespace.item
    // }),
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {},
  async mounted() {},
  methods: {
    showModal(command) {
      // Add the sent command
      this.command = command;
      this.show = true;
    },

    youtubeParser(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },

    handleData() {
      this.loading = true;

      console.log(this.src);

      const data = {
        command: this.command,
        data: {
          src: "https://www.youtube.com/embed/" + this.youtubeParser(this.src)
        }
      };

      this.loading = false;

      this.$emit("onConfirm", data);
      this.show = false;
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  width: 480px;
  height: 300px;
}
</style>
