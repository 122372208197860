<template>
  <div>
    <div
      v-if="show"
      class="fixed w-screen h-screen flex items-center justify-center z-80 top-0 left-0"
    >
      <div
        @click="show = false"
        class="bg-blue-900 opacity-50 absolute top-0 left-0 w-full h-screen"
      ></div>
      <div
        class="modal bg-white rounded shadow-lg relative z-10 flex flex-col justify-between"
      >
        <header
          class="px-4 py-3 border-b border-gray-300 font-bold text-gray-600"
        >
          {{ modalTitle }}
        </header>

        <div class="content flex p-4">
          <div class="w-full pl-3">
            <label class="block mb-6">
              <span class="label">Selecione um verbete</span>
              <select v-model="term" name="term" id="term" class="select">
                <option value="">Nenhum selecionado</option>
                <option
                  v-for="term in glossary"
                  :key="term._id"
                  :value="term._id"
                  >{{ term.titleAdmin }}</option
                >
              </select>
            </label>
          </div>
        </div>

        <footer class="px-4 py-3 border-t border-gray-300 text-right">
          <AppButton type="button" @clicked="handleData()" :loading="loading"
            >Linkar verbete</AppButton
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppButton from "@/components/AppButton";

export default {
  name: "ModalGlossary",
  components: {
    AppButton
  },
  filters: {},
  props: {
    modalTitle: String
  },
  data: function() {
    return {
      loading: false,
      file: null,

      image: null,

      command: null,
      show: false,

      term: ""
    };
  },
  computed: {
    ...mapState({
      glossary: state => state.Glossary.list
    })
  },
  watch: {},
  async created() {
    if (this.glossary.length <= 0) {
      this.$store.dispatch("Glossary/list");
    }
  },
  async mounted() {},
  methods: {
    showModal(command) {
      console.log(command);
      this.command = command;
      this.show = true;
    },

    async handleData() {
      this.loading = true;

      const data = {
        command: this.command,
        data: {
          src: this.term
        }
      };

      this.loading = false;

      this.$emit("onConfirm", data);
      this.show = false;
    },

    async detectFiles(fileList) {
      this.file = fileList[0];
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  width: 480px;
  height: 280px;
}
</style>
